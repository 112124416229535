
import { Home } from './pages';

const navHeaderRoutes = [
  {
    path: '/',
    component: Home,
  },
];

export default navHeaderRoutes;
